.scrollToTopBtn {
	z-index: 1;
	position: fixed;
	height: 50px;
	width: 50px;
	right: 5em;
	bottom: 5em;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	transition: all 0.3s;

}
.scrollToTopBtn:hover {
	background-color: #888;
}
.scrollToTopBtn::before {
	content: "";
	display: block;
	width: 0; 
	height: 0; 
	margin: auto;
	border-radius: 2px;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #00B2BF;
}
.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    overflow: hidden;
    /*background-color: #111;*/
}
.progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 4px #ccc;
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap svg.progress-circle path {
    stroke: #00B2BF;
    stroke-width: 14;
    fill: transparent;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap::before {
    position: absolute;
    font-family: 'unicons';
    content: '';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 1;
/*    background-image: linear-gradient(298deg, #00B2BF, yellow);
*/    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap::after {
    position: absolute;
    font-family: 'unicons';
    content: '';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: #00B2BF;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
svg.svg-icon {
    position: absolute;
    top: 0;
    left: 0;
    fill: #00B2BF;
    stroke: #00B2BF;
    stroke-width: 1.8;
    width: 46px;
    height: 46px;
    padding: 12px;
    transform: rotate(90deg);

}