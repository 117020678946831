
/* ELEMENT RESETS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Khula:wght@400;600;700;800&display=swap');*/
/**,*::before,*::after {
	box-sizing:inherit;
}*/

/*:root {
    --color-header: #191919;
    --color-text: #323232;
    --color-text-dark: #666666;
    --color-neutral-light: #405379;
    --color-main-light:#ccc;
    --color-main-medium:#444;
    --color-main-dark:#ccc;
    --color-black: black;
    --color-gray-light: #eee;
    --color-gray-dark: #222;
    --color-white: white;
    --color-highlight: #0F2C2F;
    --gradient: linear-gradient(90deg, #ee6352, purple, #ee6352);
    --header-height:3rem;
}*/
:root {
    --color-header: #fff;
    --color-text: #efefef;
    --color-text-dark: #cccccc;
    --color-neutral-light: #405379;
    --color-main-light:#ccc;
    --color-main-medium:#efefef;
    --color-main-dark:#ccc;
    --color-black: white;
    --color-gray-light: #444;
    --color-gray-dark: #666;
    --color-white:#111;
    --header-height:3rem;
    --color-green: #18B0B3;
    --color-red: #FE3B3C;
}

html,
body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    /*padding-bottom: 300px;*/
    background-color: var(--color-white);
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 50% 50%);*/


}
body::before {
    content: "";
    display: block;
    /*height: 800px;*/
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*background: linear-gradient(130deg,#FE3B3C,#000000 45.07%,#18B0B3 76.05%);*/
    /*background: linear-gradient(180deg,#A7EDE5,#ee6352 45.07%,#7538EB 100%);*/
    /*background: linear-gradient(135deg, #222 ,#000 80%,#000 85%,#ee6352 45.07%,#7538EB 100%);*/
    background: linear-gradient(135deg, #222 ,#000 100%);
    /*clip-path: polygon(100% 0,0 0,0 77.5%,1% 77.4%,2% 77.1%,3% 76.6%,4% 75.9%,5% 75.05%,6% 74.05%,7% 72.95%,8% 71.75%,9% 70.55%,10% 69.3%,11% 68.05%,12% 66.9%,13% 65.8%,14% 64.8%,15% 64%,16% 63.35%,17% 62.85%,18% 62.6%,19% 62.5%,20% 62.65%,21% 63%,22% 63.5%,23% 64.2%,24% 65.1%,25% 66.1%,26% 67.2%,27% 68.4%,28% 69.65%,29% 70.9%,30% 72.15%,31% 73.3%,32% 74.35%,33% 75.3%,34% 76.1%,35% 76.75%,36% 77.2%,37% 77.45%,38% 77.5%,39% 77.3%,40% 76.95%,41% 76.4%,42% 75.65%,43% 74.75%,44% 73.75%,45% 72.6%,46% 71.4%,47% 70.15%,48% 68.9%,49% 67.7%,50% 66.55%,51% 65.5%,52% 64.55%,53% 63.75%,54% 63.15%,55% 62.75%,56% 62.55%,57% 62.5%,58% 62.7%,59% 63.1%,60% 63.7%,61% 64.45%,62% 65.4%,63% 66.45%,64% 67.6%,65% 68.8%,66% 70.05%,67% 71.3%,68% 72.5%,69% 73.6%,70% 74.65%,71% 75.55%,72% 76.35%,73% 76.9%,74% 77.3%,75% 77.5%,76% 77.45%,77% 77.25%,78% 76.8%,79% 76.2%,80% 75.4%,81% 74.45%,82% 73.4%,83% 72.25%,84% 71.05%,85% 69.8%,86% 68.55%,87% 67.35%,88% 66.2%,89% 65.2%,90% 64.3%,91% 63.55%,92% 63%,93% 62.65%,94% 62.5%,95% 62.55%,96% 62.8%,97% 63.3%,98% 63.9%,99% 64.75%,100% 65.7%);*/
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
}


* {
    box-sizing: border-box;
}

h1, h2, h3, h4 ,h5, h6, a, th {
	font-family: 'Poppins', sans-serif;
	letter-spacing: -2%;
    word-spacing: 5px;
	color: var(--color-header);
}
span.teko {
    font-size: 2em;
    line-height: 1;
    margin-right: 20px;
    /*font-family: 'Teko', sans-serif;*/
    font-weight: 400;
    color: #EEA84C;
}
.content {
    max-width: 640px;
}
.hoverimg {
    z-index: -10;
}
.portfolio-intro {
    height: 100vh;
    position: relative;
    /*top: -120px;*/
    /*overflow-y: hidden;*/
}
.portfolio-intro.flex-width-60 {
    /*margin: 2em auto;*/
}
.portfolio-menu {
   width: 100%;
   padding-bottom: 150px;
   overflow: hidden;
}
.portfolio-menu h1 {
    margin: 1em 0;
    margin-bottom: 0.5em;
	font-size: 4em;
}
.portfolio-menu p {
    font-size: 0.95em;
}
.portfolio-menu h2.oneLiner {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: 400;
}
.portfolio-menu h2.oneLiner strong{
    font-weight: 400;
}
.portfolio-content {
    scroll-behavior: smooth;
}
h1, h2, h3 {
    margin-top: 1em;
	margin-bottom: 0.25em;
    font-weight: 800;
}
h4,h5,h6 {
    font-weight: 400;
    margin-top: 2em;
	margin-bottom: 1.5em;
	color: var(--color-main-medium);
}
ul,ul>li {
    padding: 0;
    margin: 0;
    letter-spacing: 2px;
    line-height: 2.0em;
    font-size: 12px;

    /*list-style: none;*/
    color: var(--color-text);
}


ol,ol>li{
	padding: 0;
    margin: 0;
    /*font-weight: 400;*/
    font-style: normal;
    letter-spacing: 2px;
    line-height: 2.0em;
    margin-top: 0.8em;
    font-size: 13px;
    list-style: none;
    color: var(--color-text);
    list-style-type: decimal;
}
ul,ol {
    padding-left: 1.5em;
}
ul,ul>li.brief {
    list-style: none;
    padding-left: 0;
}
nav ul,
nav li {
	text-transform: uppercase;
}
a {
    /*outline: none;*/
    padding: 0;
    margin: 0;
    text-decoration: none;
}

p {
    color: var(--color-text);
    font-size: 13px;
    letter-spacing: -2%;
    word-spacing: 2px;
    line-height: 2.0em;
    display: block;
    
}
span {
    color: var(--color-text)
}
[class*='col-xs-'] {
	/*margin: 10px;*/
}
[class*='col-md-'] {
	/*margin: 200px;*/
}
[class*='col-xl-'] {
	/*margin: 20px;*/
}

[class*='col-']>.card {
	height: 100%;
}
.title-center {
	text-align: center;
}
.text-center {
    text-align: center;
}
.title-indent {
	margin-left: 20px;
}
.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	/*grid-gap: 50px;*/
}
.grid-gap-xxs {
	grid-gap: 5px 0;
}
.grid-gap-xs {
	grid-gap: 10px;
}
.grid-gap-md {
	grid-gap: 2em;
}
.grid-gap-xl {
	grid-gap: 50px 20px;
}
.flex {
	display: flex;
	flex-direction: column;
}
.flex-center-margin-xs>* {
	margin: 10px;
}
.align-item-center {
    display: flex;
    align-items: center;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-center>* {
    margin: auto;
}
.flex-justify-between {
	display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.flex-justify-end {
	display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: justify;
    justify-content: flex-end;
}
.flex-align-items-end {
	display: flex;
	align-items: flex-end;
}
.flex-align-content-around {
	display: flex;
	align-content: stretch;
	flex-wrap: wrap;
	/*flex-direction: column;*/
}
/*.flex-align-content-center {
	display: flex;
	align-content: center;
}*/
.full-size-width img {
	width: 100%;
}
.flex-width-60 {
	margin: auto;
	width: 60%;
}
.flex-width-70 {
	margin: auto;
	width: 70%;
}
.flex-width-80 {
	margin: auto;
	width: 80%;
}

.project-box {
    position: relative;
	padding: 1em 0 0 0;
/*    margin-top: 2em;*/
}
.project-box:first-child {
/*    margin-top: 4em;*/
}
h2.project-index {
    opacity: 0.08;
    position: absolute;
    top: -1.8em;
    /*left: -10px;*/
    margin: 1em 0;
    font-size: 10em;
    color: var(--color-black);
    font-weight: 800;
    /*-webkit-text-stroke: 2px var(--color-black);*/
}
.project-box:nth-child(2n+1) h2.project-index {
    left: -50px;
}
.project-box:nth-child(2n) h2.project-index {
    left: 0;
}
@media (max-width: 1024px) {
	.flex-width-60 {
		width: 90%;
	}
	.flex-width-70 {
		width: 90%;
	}
	.flex-width-80 {
		width: 90%;
	}
	.project-box {
		padding: 0;
	}
    .project-box:nth-child(2n+1) h2.project-index {
        left: 0;
    }
}

.col-1 {	grid-column: span 1;	}
.col-2 {	grid-column: span 2;	}
.col-3 {	grid-column: span 3;	}
.col-4 {	grid-column: span 4;	}
.col-5 {	grid-column: span 5;	}
.col-6 {	grid-column: span 6;	}
.col-7 {	grid-column: span 7;	}
.col-8 {	grid-column: span 8;	}
.col-9 {	grid-column: span 9;	}
.col-10 {	grid-column: span 10;	}
.col-11 {	grid-column: span 11;	}
.col-12 {	grid-column: span 12;	}

@media (min-width: 1024px) {
	.col-xs-1 {	grid-column: span 1;	}
	.col-xs-2 {	grid-column: span 2;	}
	.col-xs-3 {	grid-column: span 3;	}
	.col-xs-4 {	grid-column: span 4;	}
	.col-xs-5 {	grid-column: span 5;	}
	.col-xs-6 {	grid-column: span 6;	}
	.col-xs-7 {	grid-column: span 7;	}
	.col-xs-8 {	grid-column: span 8;	}
	.col-xs-9 {	grid-column: span 9;	}
	.col-xs-10 {grid-column: span 10;	}
	.col-xs-11 {grid-column: span 11;	}
	.col-xs-12 {grid-column: span 12;	}
}
@media (min-width: 1280px) {
	.col-md-1 {	grid-column: span 1;	}
	.col-md-2 {	grid-column: span 2;	}
	.col-md-3 {	grid-column: span 3;	}
	.col-md-4 {	grid-column: span 4;	}
	.col-md-5 {	grid-column: span 5;	}
	.col-md-6 {	grid-column: span 6;	}
	.col-md-7 {	grid-column: span 7;	}
	.col-md-8 {	grid-column: span 8;	}
	.col-md-9 {	grid-column: span 9;	}
	.col-md-10 {grid-column: span 10;	}
	.col-md-11 {grid-column: span 11;	}
	.col-md-12 {grid-column: span 12;	}
}
@media (min-width: 1440px) {
	.col-xl-1 {	grid-column: span 1;	}
	.col-xl-2 {	grid-column: span 2;	}
	.col-xl-3 {	grid-column: span 3;	}
	.col-xl-4 {	grid-column: span 4;	}
	.col-xl-5 {	grid-column: span 5;	}
	.col-xl-6 {	grid-column: span 6;	}
	.col-xl-7 {	grid-column: span 7;	}
	.col-xl-8 {	grid-column: span 8;	}
	.col-xl-9 {	grid-column: span 9;	}
	.col-xl-10 {grid-column: span 10;	}
	.col-xl-11 {grid-column: span 11;	}
	.col-xl-12 {grid-column: span 12;	}
}

h4.col-12.title-center::before {
    display: inline-block;
    position: relative;
    /*top: 40%;*/
    content: '';
    width: 100%;
    /*border-bottom: 1px solid var(--color-white);*/
}
p.interview-num  {
	font-size: 5em;
	margin: 0 auto;
	font-weight: bold;
}
.interview-1 h5,
.interview-2 h5,
.interview-2 p {
	font-size: 1.2rem;
	margin: 0 auto;
	font-weight: bold;
	/*width: 100%;*/
}
.interview-2 h5 {
	text-align: center;
}
.interview-2 p {
	font-size: 1.5rem;
}
.user-quote {
	position: relative;
	background-color: #efefef;
	border-radius: 5px;
	margin: 1rem 0;
	/*padding: 0 1rem;*/
}
.user-quote li {
	padding: 1rem 1rem 1rem 2rem;
    list-style: none;
    /*padding: 0;*/
}
.quote {
	font-family: 'IM Fell Great Primer SC', serif;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 10px;
	line-height: 2rem;
}
.quote:nth-child(1) {
	position: absolute;
	top: 18px;
	left: 8%;
	/*position: relative;*/
	/*top: 1rem;*/
	/*left: 5px;*/
}
.quote:nth-child(2) {
	position: absolute;
	bottom: 0;
	right: 4%;
	/*position: relative;*/
	/*top: 1rem;*/
	/*left: 5px;*/
}


.zoom-img {
	margin: 2em auto;
}
.img-box {
    display: flex;
    align-items: center;
    justify-content: center;
	
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
	width: 100%;
    cursor: pointer;
    /*border: 1px solid red;*/
    border-radius: 2px;
    transition: all 0.2s;
}
.img-box-h {
	width: 0;
	height: 100%;
}
.img-box:hover {
    /*opacity: 0.9;*/
}
.img-box::before {
    content: '';
    display: block;
    /*padding-top: 70%;*/
}
.img-box>img {
	position: absolute;
}
.img-box-synthesis.img-box {
	overflow: hidden;
	width: 50%;
}
.img-box-synthesis.img-box p {
	font-size: .3rem;
	line-height: 0.7rem;
}

.img-box-synthesis.img-box::before {
    padding-top: 100%;
}
.img-box-logotype.img-box::before {
    padding-top: 30%;
}
.img-box-type.img-box::before {
	padding-top: 45%;
}
.img-box-color.img-box::before {
	padding-top: 30px;
	/*padding-top: 50%;*/
}


.persona img {
	/*border: 1px solid var(--color-purple-dark);*/
	box-shadow: 1px 1px 10px #ddd; 
}
.zoom-img .test-num img {

	width: 50%;
	/*max-height: 100px;*/
}
strong {
    color: #00B2BF;
    font-weight: 400;
}
.nasa strong {
    color: #FFCC46;
}
.redesign-goals>div {
	border: 2px solid rgba(0,178,191,0.2);
	/*background-color: rgba(0,178,191,0.1);*/
	border-radius: 5px;
	padding: 1rem 2rem 2rem 2rem;
}
.list-circle {
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #00B2BF;
	margin: 0 1rem 0 0;
}
.testing img {
	
}
#content_progress {
      /* Positioning */
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      /*width: 100%;*/
      height: 8px;
      /*border-radius: */
      /*height: 100%;*/
      /*width: 5px;*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      color: #00B2BF;
      transform-origin: top left;
      transform: rotate(90deg);
      /*left: 50%;*/
	/*top: 50%;*/
	margin-left: 8px;

}

#content_progress::-webkit-progress-bar {
      background-color: transparent;
}

#content_progress::-webkit-progress-value {
      background-color: #00B2BF;
}

#content_progress::-moz-progress-bar {
      background-color: #00B2BF;
}


.mycontainer {
    height: 100%;
    width: 100%;
    position: absolute;
    /*padding-bottom: 300px;*/
}

.menu {
    position: absolute;
    width: 100%;
    height: 80px;
    z-index: 100;
    /*border: 1px solid red;*/
}
.menu .menu-link {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 10%;
    height: 80px;
    
}

.menu .menu-link li {
    display: inline-block;
    margin: auto 1em;
}
.menu .menu-logo {
    height: 80px;
    overflow: hidden;
    position: absolute;
    left: 5%;
    letter-spacing: 4px;
    font-size: 24px;
    font-weight: 600;
}
.menu .menu-logo img {
    height: 48%;
    margin-right: 20px;

}
.menu .menu-logo span {
    opacity: 0;
}
.menu a {
    color: var(--color-text);
    transition: all 0.2s;
    text-transform: uppercase;
    font-weight: 600;

}
/*.menu li:hover{
    border-bottom: 2px solid var(--color-black);
}
*/
.menu a:hover {
    color: var(--color-text-dark);
}
.menu a.active {
    color: var(--color-highlight);
    font-weight: 600;
    opacity: 0.3;
    /*text-decoration: underline;*/

}
@media screen and (max-width: 768px) {
    
    .menu .menu-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--color-white);
        right: auto;
        padding-top: 60px;
    }
    .menu .menu-link.laptop {
        display: none;
    }
    .burger {
        display: block; 
    } 
    .menu {
        width: 100%;
        position: absolute;
        margin-left: 0;
        padding: 0;
        z-index: 1;

    }
    .menu .menu-link li {
        display: block;
        width: 100%;
        line-height: 60px;
        text-align: center;
        margin: auto;
        background-color: var(--color-white);
    }
    
    .menu nav {
        width: 100%;
        top: 170px;
    }
    ul {
        margin: 0;
    }

}



.hero-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}
.hero-img-box::before {
    content: '';
    display: block;
    padding-top: 50%;
}
.hero-img-box>img{
    position: absolute;
    margin: auto;
    width: 100%;
}

.pf-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    /*padding: 20px;*/
    transition: all 0.2s;
    cursor: pointer;
    /*border: 1px solid red;*/
    border-radius: 5px;
    opacity: 1;
}
.pf-box img:first-child {
    opacity: 0;
}
.pf-box img:last-child {
    opacity: 1;
}
.pf-box:hover img:first-child {
    /*opacity: 0.9;*/
    opacity: 1;
}
.pf-box:hover img:last-child {
    opacity: 0;
}
.pf-box::before {
    content: '';
    display: block;
    padding-top: 30%;
}
.pf-box a {
    position: absolute;
}
.pf-box img{
    position: absolute;
    margin: auto;
    width: 100%;
}
.pf-box img:hover {
    /*opacity: 0.8;*/
}
.pf-intro {
    gap: 20px;
}
.pf-intro h2::after,
.pf-intro h3::after {
/*    content: '';
    display: inline-block;
    position: relative;
    height: 100%;
    width: 2px;
    background-color: var(--color-text);*/
}
.tag:nth-child(1) {
    margin-left: 0;
}
.tag {
    background-color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-dark);
    border-radius: 5px;
    color: var(--color-white);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: .5px;
    margin: 0 5px;
    padding: 5px 1em;
    text-transform: uppercase;
    white-space: pre-line;
}
.pf-tag .tag:nth-child(1) {
    margin-left: 0;
}
.pf-tag .tag {
    display: inline-block;
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-light);
    border-radius: 5px;
    color: var(--color-black);
    font-size: 10px;
    letter-spacing: .5px;
    margin: 0 8px 8px 0;
    padding: 1px 1em;
    text-transform: uppercase;
    white-space: pre-line;
}
.portfolio-content-coding .pf-tag .tag {
    background-color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-dark); 
    color: var(--color-white);
}
.section-box {
    visibility: hidden;
    padding: 1em 0;
}
.section-box.highlight::before {
    z-index: -100;
    content: '';
    display: block;
    position: absolute;
    width: 200%;
    left: -50%;
    height: calc(100%);
    background-color: var(--color-gray-light);
}
.section-box.brief {
    padding-bottom: 2em;
    border-top: 1px solid var(--color-gray-light);
    border-bottom: 1px solid var(--color-gray-light);

}
.section-box.appear {
    visibility: visible;
}
.section-title {
    margin: 3em 0 1rem 0;
}
.zoom-img {
    /*border: 1px solid red;*/
}
.zoom-img img {
    width: 100%;
    /*cursor: zoom-in;*/
}











.about-intro {
    min-height: 90vh;
    margin-top: 0;
    /*margin: 3rem 0;*/
    /*padding-top: 150px;*/
}
.profile-photo-box {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 120%;
    margin: 2em 0;

}
.profile-photo {
    position: absolute;
    top: 0%;
    left: 10%;
    width: 80%;
    height: 100%;
    
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    
}
.profile-photo img {
    position: absolute;
    margin: auto;
    width: 100%;
    height: auto;
}
.profile-description {
    padding: 0 3em;
}
.profile-description a {
    font-weight: bold;
}

.mouse {
    position: absolute;
    width: 22px;
    height: 42px;
    bottom: 20px;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid #fff;
    -webkit-animation: intro 1s;
    animation: intro 1s;
}

.scroll {
    display: block;
    width: 12px;
    height: 12px;
    margin: 6px auto;
    border-radius: 6px;
    background: #fff;
    -webkit-animation: finger 2s infinite;
    animation: finger 2s infinite;
}
@keyframes intro {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes finger {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

.burger {
    display: none;
    position: absolute;
    top: 30px;
    width: 30px;
    right: 5%;
    cursor: pointer;
}
.burger .line {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--color-black);
    margin-bottom: 5px;
}
#menu_open{
    z-index: 2;
}
@media screen and (max-width: 768px) {
    .profile-description {
        padding: 2em 10%;
    }
    .burger {
        display: block;
    }    
}
#menu_close {
    z-index: 2;
    /*display: none;*/
    /*transform: translateX(15px) rotate(-45deg) translateY(-10px);*/
}
#menu_close .line {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #424242;
    margin-bottom: 0;
}
#menu_close .line:nth-child(1) {
    transform: translateY(4px) rotate(-45deg);
}
#menu_close .line:nth-child(2) {
    transform: rotate(45deg);
}
.icon li {
    display: inline-block;
 }
.icon img {
    fill: #fff;
    width: 25px;
    height: 25px;
}

.patterns {
    /*border: 1px solid #ccc;*/
    overflow: hidden;
}
.patterns img {
    max-height: 300px;
}

.pattern_1,
.pattern_2,
.pattern_3 {
    width: 400%;
    height: 200%;
    transform: translateX(-40%) translateY(-30%) rotate(45deg);

}
.patterns:nth-child(3) {
    background-color: #0a1f48;
}
.pattern_1 {
    background-color: #fff;
    background-image: linear-gradient(45deg, #b8cdde 25%, transparent 25%, transparent 75%, #235B9E 75%, #235B9E),
    linear-gradient(45deg, #235B9E 25%, transparent 25%, transparent 75%, #b8cdde 75%, #b8cdde);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;

}
.pattern_2 {
    /*background-color: #0a1f48;*/
    background-image: linear-gradient(45deg, #235B9E 25%, transparent 25%, transparent 75%, #b8cdde 75%, #b8cdde),
    linear-gradient(45deg, #b8cdde 25%, transparent 25%, transparent 75%, #235B9E 75%, #235B9E);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;
    opacity: 8%;
}

.pattern_3 {
    background-color: #fff;
    background-image: linear-gradient(45deg, #235B9E 25%, transparent 25%, transparent 75%, #b8cdde 75%, #b8cdde),
    linear-gradient(45deg, #b8cdde 25%, transparent 25%, transparent 75%, #235B9E 75%, #235B9E);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;
    opacity: 8%;
}



@media screen and (max-width: 768px)
{

    #photo-nav {
        display: none;
    }
}



/*img {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation: scaleUp 0.65s ease-in-out forwards;
    animation: scaleUp 0.65s ease-in-out forwards;
}

@-webkit-keyframes scaleUp {
    0% { }
    100% { -webkit-transform: scale(1); }
}

@keyframes scaleUp {
    0% { }
    100% { -webkit-transform: scale(1); transform: scale(1); }
}*/

.fullscreen-container {
    position: relative;
    width: 100%;
    min-height: 80vh;
    /*background-color: #eee;*/
}

.sticky-container {
    position: sticky;
    top: 30.1vh;
    
}
.isSticky {
    /*background-color: red;*/
}
.threed-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 50vh;
    perspective: 50px;
    transform-style: preserve-3d;
    overflow-y: auto;
    overflow-x: hidden;
    overflow: hidden;

    
}
.mockup-text-container {
    padding: 40px;
    /*padding-left: 5em;*/
    margin: auto;


    perspective: 50px;
    transform-style: preserve-3d;
    overflow-y: hidden;
    overflow-x: hidden;

}
/*::-webkit-scrollbar {
    display: none;
}*/
.mockup-text-container>* {
    /*position: absolute;*/
    /*transform: translateZ(-100px) translateX(0) scale(3);*/
}
.mockup-container {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 400px;
    height: 308px;

}

.mockup-containe-inner {
    position: relative;
    /*overflow: scroll;*/
    /*background-color: rgba(200,200,200,0.9);*/
    /*top: 30.1vh;*/
    width: 400px;
    height: 308px;

    display: flex;
    align-items: center;
    justify-content: center;


}
/*.mockup-containe-inner::after {
    content: '';
    display: block;
    position: absolute;


    width: 400px;
    height: 308px;

    box-sizing: content-box;
    transform: translateZ(-300px) translateX(0) scale(7);
    overflow: hidden;
    border: 200px solid #fff;

}*/
.mockup-inner-img {

    position: absolute;
    width: 380px;
    top: 10px;
    /*transition: all 5s;*/
    /*transform: translateZ(-50px) translateX(0) translateY(250px) scale(2);*/
    -webkit-animation: scrollUpandDown 10s ease-in-out infinite;
    animation: scrollUpandDown 10s ease-in-out infinite;
    
}

.mockup-outer-img {
    /*z-index: 1;*/
    display: block;
    position: absolute;
    /*height: 100%;*/
    width: 400px;
    /*height: 308px;*/
    /*transform: translateZ(-100px) translateX(0) scale(3);*/
    
}

@keyframes scrollUpandDown {
    0% {
        top: 10px;
        /*bottom: auto;*/
    }
    80% {
        top: -40%;
        /*bottom: 10px;*/
    }
    100% {
        top: 10px;
        /*bottom: 10px;*/
    }
    
}
/*@-webkit-keyframes scrollUpandDown {
    0% {
        top: 10px;
        bottom: auto;
    }
    50% {
        top: auto;
        bottom: 10px;
    }
    100% {
        top: 10px;
        bottom: auto;
    }
}*/
.mockup-outer-img2 {
    z-index: 1;
    /* display: none; */
    position: fixed;
    /*top: 0;*/
    width: 400px;
}








.mockup-container2 {
    position: sticky;
    top: 35.1vh;
    margin-top: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    /*flex-direction: column; */
    /*overflow-x: hidden;*/
    width: 400px;
    height: 308px;
    background-color: red;
}
.mockup-containe-inner2 {
    position: relative;
    /*overflow: scroll;*/
    background-color: rgba(200,200,200,0.9);
    /*top: 30.1vh;*/
    width: 400px;
    height: 308px;

    display: flex;
    align-items: center;
    justify-content: center;

    perspective: 50px;
    transform-style: preserve-3d;


}
