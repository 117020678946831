.footer {
	height: 150px;
}
.icons img {
	background-color: #fff;
	fill: #ccc;
	width: 20px;
  /*mask-image: url("/assets/icons/calendar.svg");*/
	margin: 1em;
}
.icons .icon {
	display: inline-block;
	background-color: var(--color-black);
	/*fill: #ccc;*/
	width: 20px;
	height: 20px;
	-webkit-mask-size: contain;
  	mask-size: contain;
  	-webkit-mask-position: center;
 	 mask-position: center;
 	-webkit-mask-repeat: no-repeat;
 	 mask-repeat: no-repeat;
 	 margin: 1em;
 	 /*mask-image: url("./../../assets/img/icon/linkedin.svg");*/
}
.icons:nth-child(1) .icon {
	mask-image: url("./../../assets/img/icon/linkedin.svg");
}
.icons:nth-child(2) .icon {
	mask-image: url("./../../assets/img/icon/instagram.svg");
}
.icons:nth-child(3) .icon {
	mask-image: url("./../../assets/img/icon/gmail.svg");
}
.icons img:hover {
	fill: #ccc;
}