.container {
    /*height: 100%;*/
    width: 100%;
    position: absolute;
}

.menu1 {
    /*z-index: 1;*/
    position: fixed;
    top: 0;
    width: 160px;
    height: 100%;
    margin-left: 75px;
    padding: 75px 0;
    /*background-color: #d4e2c0;*/
}
.menu1 .menu-title {
    font-family: 'Exo', 'Roboto', sans-serif;
    letter-spacing: 4px;
    display: block;
    font-size: 24px;
    /*font-weight: 400;*/
    /*text-transform: uppercase;*/
    margin-bottom: 80px;
}
.menu1 a,
.nav-breadcrumb-link {
    color: #666;
    transition: all 0.2s;
    font-size: 12px;
}
.menu1 a:hover,
.nav-breadcrumb-link:hover {
    color: #111;
    font-weight: bolder;
    /*border-bottom: 2px solid #111;*/
}
.menu1 a.active,
.nav-breadcrumb-link.active {
    color: #ccc;
}
.nav-breadcrumb-link {
    display: inline-block;
}
.nav-breadcrumb-separator {
    margin: 0 5px;
    color: #aaa;
}
.nav-breadcrumb-separator:after {
    content: '\203A';
}
.img-container {
    /*position: relative;*/
    max-width: 2000px;
    margin: 150px 10%;
}
.img-container .img-cover {
    /*display: none;*/

}
.catalog-outer .img-cover {
    position: absolute;
    width: 100%;
    height:100%;
    /*height: calc(100% - 120px);*/
    /*border: 5px solid green;*/
    display: flex;
    z-index: 100;

}
.catalog-outer .img-cover>div {
    /*position: absolute;*/
    /*z-index: 200;*/
    flex: 1;
    /*border: 5px solid green;*/
}
.catalog-outer .img-cover>div.left:hover {
    cursor: w-resize;
}
.catalog-outer .img-cover>div.middle:hover {
    cursor: n-resize;
}
.catalog-outer .img-cover>div.right:hover {
    cursor: e-resize;
}
.catalog-outer {
	/*display: none;*/
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: flex;
    background-color: rgba(255,255,255,.5);
    z-index: 1;
    background-color: rgba(0,0,0,.8);

}
.catalog-outer img{
	/*position: absolute;*/
	height: 80%;
	z-index: 100;
}

.img-container>div.img-box {
	visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    /*padding: 20px;*/
    transition: all 0.3s;
    cursor: pointer;
}
.img-container>div.img-box.appear {
	visibility: visible;
}
.img-container>div.img-box:hover {
    opacity: 0.9;
}
.img-container>div.img-box::before {
    content: '';
    display: block;
    padding-top: 125%;
}
.home-screen-img.img-container {
    /*margin: ;*/
    height: calc(100% - 140px);
}

.home-page.img-container>div.img-box::before {

    padding-top: 60%;
}
.home-screen-img.img-container>div.img-box::before {

    padding-top: 240%;
}
.home-screen-img.img-container>div.img-box>img {
    height: 100%;
    width: auto;
}
.img-container>div.img-box img {
    position: absolute;
    margin: auto;
    width: 100%;

}



/***************************************************              ***************************************************/
img {
    opacity: 1;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
}
.img-container>div.img-box img.horizontal {
    margin: auto;
    height: 100%;
    width: auto;
    /*opacity: 0.9;*/

}

.catalog-outer>div.img-box:hover {
    opacity: 1;
    /*cursor: n-resize;*/
}

.catalog-outer>div.img-box {
    display: none;
}
.catalog-outer>div.img-box.active {

    display: flex;
    justify-content: center;
    height: 100%;
}
.catalog-outer>div.active img {
    position: absolute;
    margin: auto;
   /* top: -17%;
    left: 0;*/
    
    height: 100%;
    width: auto;
    /*opacity: 0.9;*/

}



#photo-nav {
    /*z-index: 1;*/
    position: fixed;
    bottom: 0;
    width: 160px;
    height: 200px;
    margin-left: 75px;
    padding: 75px 0;

}
#photo-nav ul {
    position: absolute;
    bottom: 75px;
    width: 100%;
    margin: 0;
    /*padding: 0;*/
}
#photo-nav li {
    display: inline-block;
    margin: 0 8px;
    width: 8px;
    height: 8px;
    
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    
}
#photo-nav li.active {
    background-color: #888;
}
#photo-nav li:hover {
    background-color: #888;
}
.profile-photo-box {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;

}
.profile-photo {
    position: absolute;
    top: 0%;
    left: 10%;
    /*bottom: 0;*/
    width: 80%;
    height: 100%;
    
    box-sizing: border-box;
    overflow: hidden;
    /*border: 1px solid green;*/
    /*border-radius: 50%;*/
    border-radius: 5px;
    
}
.profile-photo img {
    position: absolute;
    /*top: 0;*/
    margin: auto;
   /* bottom: 0;
    right: 0;
    left: 0;*/
    /*height: 100%;*/
    width: 100%;
    height: auto;
}

.profile-description a {
    font-weight: bold;
}




.pf-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.3s;
    cursor: pointer;
    /*margin: 100px;*/
    /*border-radius: 10px;*/
}
.pf-box:hover {
    /*opacity: 0.9;*/
}
.pf-box::before {
    content: '';
    display: block;
    padding-top: 70%;
}
.pf-box a {
    position: absolute;
}
.pf-box img{
    /*position: absolute;*/
    margin: auto;
    width: 100%;
}
.pf-box img:hover {
    /*opacity: 0.8;*/
}


/*@media screen and (max-width: 2500px)
{
    .img-container {
       
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .img-container.about {
        grid-template-columns: 1fr 2fr;
    }
}
@media screen and (max-width: 1700px)
{
    .img-container {
       
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .img-container.about {
        grid-template-columns: 1fr 2fr;
    }
}*/
/*@media screen and (max-width: 1440px) {
    .img-container {
       
        grid-template-columns: 1fr 1fr 1fr;
    }
    .img-container.about {
        grid-template-columns: 1fr 2fr;
    }
}
@media screen and (max-width: 1280px)
{
    .img-container {
        grid-template-columns: 1fr 1fr;
    }
    .img-container.about {
        grid-template-columns: 1fr 2fr;
    }
}
@media screen and (max-width: 1024px)
{
    .img-container {
        grid-template-columns: 1fr;
    }
    .img-container.about {
        grid-template-columns: 1fr;
    }
}*/
@media screen and (max-width: 768px)
{
   /* .burger {
        display: block; 
    } */
    .menu1 {
        width: 100%;
        position: relative;
        height: auto;
        margin-left: 0;
        padding: 0;
        background-color: #fff;

    }
    .menu1 .menu-title {
        margin-bottom: 0;
        padding: 30px;
    }

    .menu1 .menu-inner {
        display: none;
        /*visibility: hidden;*/

        z-index: 100;
        position: absolute;
        top: 0;
        
        width: 100%;
        margin: 0;
        padding: 30px;
        padding-top: 50px;

        text-align: center;
        background-color: #fff;

    }
    .menu1 nav {
        /*display: flex;*/
        width: 100%;
        top: 170px;
        
        /*height: 600px;*/
        /*flex-flow: column;*/
    }
    
    .img-container {
        margin: 120px 20px;
        
    }

    .img-container>div.img-box {
        position: relative;
        display: block;
        flex: none;
        padding-bottom: 0;
        width: 100%;

    }

    /*.home-screen-img.img-container>div.img-box::before,*/
    .home-page.img-container>div.img-box::before,
    .img-container>div.img-box::before {
        
        padding-top: 0;

    }
    .img-container>div.img-box img {
        position: inherit;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width: 100%;

    }
    .home-screen-img.img-container>div.img-box>img{
        position: absolute;
        left:auto;
        right:auto;
        margin: auto;
        height: 100%;
        width: auto;
    }
    .img-container>div.img-box:hover {
        opacity: 1;
        cursor: alias;
    }
    .img-container>div.img-box img.horizontal {

        width: 100%;

    }

    #photo-nav {
        display: none;
    }
    .profile-photo {
        left: 25%;
        width: 50%;
        height: 50%;
    }
    .profile-photo-box {
        padding-top: 100%;
        margin-bottom: -30%;
    }
    div#product-search-result {
        margin-top: 0;
    }
    .checkout-form-section {
        margin: 20px 0 20px 0;
    }
    .flex-width-60 {
        width: 80%;
    }
}
